export const mutations = {
   visiblePageLoader(state, bool) {
      state.pageLoader = bool
   },
   visibleCategoriesLoader(state, bool) {
      state.catLoader = bool
   },
   setDocs(state, docs) {
      state.docs = docs
   },
   setCurrentMetaData(state, settings) {
      if (!state.currentMetaData.find(el => el.id === settings.id))
         state.currentMetaData.push(settings)
   },
   changePerPage(state, num){
      state.pagination.perPage = num
   },
   setDocumentsCategories(state, categories){
      state.documentsCategories = categories
   },
   setTotalRecords(state, records){
      state.pagination.totalRecords = records
   },
   setSearchValue(state, data){
      state.pagination.skip = 0
      state.filter.search = data
   },
   setSkipRecords(state, count){
      state.pagination.skip = count
   },
   setCategoryId(state, id){
      state.pagination.skip = 0
      state.categoryId = id
   },
   setTypeId(state, id){
      state.typeId = id
   },
   setSortColumn(state, column){
      state.sortRender.orderColumn = column
   },
   setSortAscending(state, value){
      state.sortRender.sortAscending = value
   }
}

