<template src='./index.html'/>

<script>

import Body from '@/components/widgets/docs/table/body-component'
import Header from '@/components/widgets/docs/table/head-component'
import Sidebar from '@/components/widgets/docs/sidebar/sidebar-component'
import Footer from '@/components/common/table-footer-component'
import {computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import {usePaginate} from "@/composition/widgets/common/usePaginate";
import {resetAccordion} from "@/utils/accordion";
import {useResponsive} from "@/composition/widgets/common/useResponsive";

export default {
  components: {
    Body,
    Header,
    Sidebar,
    Footer,
  },
  setup() {
    const {getters, commit, dispatch} = useStore()
    
    onMounted(async () => {
      await dispatch('getDocumentsCategories')
      await dispatch('getDocs')
    })

    const sort = computed(() => getters.sort)
    const filter = computed(() => getters.filter)
    const perPage = computed(() => getters.perPage)
    const items = computed(() => getters.docs)
    const totalRecords = computed(() => getters.totalRecords)
    const pageLoader = computed(() => getters.pageLoader)
    const fields = computed(() => getters.fields)
    const categories = computed(() => getters.documentsCategories)
    const responsiveWidth = computed(() => getters.responsiveWidth)

    const {responsive} = useResponsive(responsiveWidth.value)

    const pagination = usePaginate(perPage, null, totalRecords)

    const prevPage = async () => {
      window.location.href = '#docs_app'
      await pagination.skipMinus()
      await commit('setSkipRecords', pagination.skipCount.value)
      await dispatch('getDocs')
    }

    const nextPage = async () => {
      window.location.href = '#docs_app'
      await pagination.skipPlus()
      await commit('setSkipRecords', pagination.skipCount.value)
      await dispatch('getDocs')
    }

    return {
      items,
      sort,
      filter,
      pagination,
      pageLoader,
      fields,
      responsive,
      resetAccordion,
      categories,
      prevPage,
      nextPage
    }
  },
}
</script>

<style module lang="scss" src='../../../assets/styles/docs/styles.scss'/>