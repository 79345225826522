// import api from "@/services/api";
import {api, baseURL} from "@/api/new_api";
import {parsedMetaData} from "@/store/modules/docs/services/parsedMetaData";

export const actions = {
    async getDocs({commit, state }) {
        commit('visiblePageLoader', true)
        const url = `${baseURL}/Documents`;
        const config = {
            'propertyId': state.propertyId,
            'take': state.pagination.perPage,
            'skip': state.pagination.skip,
            'categoryId': state.categoryId || null,
            'typeId': state.typeId || null,
            'sortAscending': state.sortRender.sortAscending || false,
            'orderByColumnName': state.sortRender.orderColumn,
            'queryStringForEntireModel': state.filter.search,
            'excludedCategories': state.excludedCategories?.length ? state.excludedCategories : null,
            'excludedTypes': state.excludedTypes?.length ? state.excludedTypes : null,
            'contactId': state.contactId || null
        }

        try {
            let res = await api.post(url, config)
            await commit('setTotalRecords', res.data.iTotalDisplayRecords)
            await commit('setDocs', res.data.aaData)
        } catch (e) {
            console.log(e)
        } finally {
            commit('visiblePageLoader', false)
        }
    },
    async getMetaDataByDocument({state, commit}, id) {
        const url = `${baseURL}/Documents/Metadata`
        const config = {
            params: {
                'propertyId': state.propertyId,
                'documentId': id,
            }
        }
        try {
            let res = await api.get(url, config)
            commit('setCurrentMetaData', {id, data: res.data.result ? parsedMetaData(res.data.result) : []})
        } catch (e) {
            commit('setCurrentMetaData', {id, data: []})
            console.log(e)
        }
    },
    async getDocumentsCategories({state, commit}) {
        commit('visibleCategoriesLoader', true)
        const url = '/Documents/Categories'
        const config = {
            params: {
                'propertyId': state.propertyId,
                'contactId': state.contactId || null,
                'excludedCategories': state.excludedCategories || [],
                'excludedTypes': state.excludedTypes || []
            }
        }
        try {
            let res = await api.get(url, config)
            
            if (res.data) {
                commit('setCategoryId', res.data[0].id)
            }
            
            commit('setDocumentsCategories', res.data || [])
        } catch (e) {
            console.log(e)
        } finally {
            commit('visibleCategoriesLoader', false)
        }
    }
}
