import {getCookie} from "@/store/services/getCookie";

const FILENAME = 'Title'
const UPLOADDATE = 'UploadDate'

export const state = {
    docs: [],
    fields: [
        {label: 'title', field: FILENAME},
        // {label: 'upload date', field: UPLOADDATE},
    ],
    sort: {
        UploadDate: {
            key: UPLOADDATE,
            sortType: null
        },
        Title: {
            key: FILENAME,
            sortType: null
        },
    },
    sortRender: {
        orderColumn: null,
        sortAscending: null
    },
    pagination: {
        perPage: 20,
        skip: 0,
        totalRecords: 0,
        pagesCountVariants: [20, 30, 40, 50]
    },
    filter: {
        search: null
    },
    responsiveWidth: 768,
    propertyId: getCookie('propertyId'),
    contactId: getCookie('contactId'),
    categoryId: null,
    typeId: null,
    currentMetaData: [],
    documentsCategories: [],
    pageLoader: false,
    catLoader: false,
    excludedCategories: window.docsWidget.excludedCategories.toString() || [],
    excludedTypes: window.docsWidget.excludedTypes.toString() || []
}

