<template>
  <PerPage
      @returnToFirstPage="$emit('returnToFirstPage')"
      :style="[!items.length || loading ? 'opacity: 0.5; pointer-events: none;' : null,
      responsive ? 'display: none;' : null]"/>
  <div :class="$style.rbs__docs_top">
    <div :class="$style.rbs__docs_top_item"
         v-for="item in  fields"
         :key="item.field"
         :style="[!items.length || loading ? 'opacity: 0.3; pointer-events: none;' : null]"
         @click="sorting(item.field)">
      <div :class="$style.rbs__datatable_itemwrapper">
        <div v-if="sort[item.field].sortType === 'asc'"
             :class="$style.rbs__docs_sort_active">
          <img :src="'/img/widgets/icons/active-sort-asc.svg'"
               alt="active-sort-desc">
        </div>
        <div v-if="sort[item.field].sortType === 'desc'"
             :class="$style.rbs__docs_sort_active">
          <img :src="'/img/widgets/icons/active-sort-desc.svg'"
               alt="active-sort-asc">
        </div>
        {{ item.label }}
        <div :class="$style.rbs__docs_sort_icon">
          <img :src="'/img/widgets/icons/sort-black.svg'"
               alt="sort">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PerPage from '../perpage/perpage-component'
import {resetAccordion} from "@/utils/accordion";
import {useSort} from '@/composition/widgets/common/useSort'
import {computed} from "vue";
import {useStore} from "vuex";
import {useResponsive} from "@/composition/widgets/common/useResponsive";

export default {
  name: "docs-head-component",
  components: {PerPage},
  props: ['sort', 'items', 'fields', 'searchValue', 'loading'],
  emits: ['sortBy', 'returnToFirstPage'],
  setup() {
    const {getters, dispatch, commit} = useStore()
    const responsiveWidth = computed(() => getters.responsiveWidth)
    const pagesCountVariants = computed(() => getters.pagesCountVariants)
    const sort = computed(() => getters.sort)
    const {responsive} = useResponsive(responsiveWidth.value)
    const {sortBy} = useSort(sort)

    const sorting = async field => {
      resetAccordion()
      commit('setSortColumn', field)

      const sortType = sort.value[field]['sortType']
      !sortType || sortType === 'desc'
          ? commit('setSortAscending', true)
          : commit('setSortAscending', false)

      sortBy(field, true)
      await dispatch('getDocs')
    }

    return {sorting, pagesCountVariants, responsive}
  }
}
</script>
>
<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>
