<template>
  <div :class="$style.rbs__docs_category_dropdown"
       data-dropdown
       v-click-outside="hideDropdown"
       @click="changeDropdown">
    <div :class="[$style.rbs__docs_category_dropdown_parent]">
					<span v-if="selectedItem"
                :class="$style.rbs__docs_category_dropdown_label">
						{{ selectedItem }}
					</span>
      <span v-else
            :class="$style.rbs__docs_category_dropdown_empty">
						Choose Category
      </span>
      <div :class="$style.rbs__docs_category_dropdown_arrowicon">
        <img :src="'/img/widgets/icons/chevron-down.svg'"
             :style="[visible ? 'transform: translate(0, 0) rotate(180deg)' : null]"
             alt="chevron-down">
      </div>
    </div>
    <div :class="$style.rbs__docs_category_dropdown_items"
         v-show="categories?.length && visible">
      <div :class="$style.rbs__docs_category_dropdown_item"
           data-acc
           v-for="category in categories" :key="category.id">
        <span
            @click.stop="selectCategory($event, category)"
            data-acc-name>
          {{ category.name }}
        </span>
        <template v-if="category.types.length">
          <div :class="$style.rbs__docs_category_dropdown_children"
               v-for="item in category.types" :key="item.id">
            <span
                @click.stop="selectType($event, item, category)"
                data-acc-item>
              {{ item.name }}
            </span>
          </div>
        </template>
      </div>
    </div>
    <div :class="$style.rbs__docs_category_dropdown_items"
         v-show="visible && !categories?.length">
      <div :class="$style.rbs__docs_category_dropdown_not_items">
        Empty
      </div>
    </div>
  </div>
</template>

<script>

import {useCategories} from "@/composition/widgets/docs/useCategories";
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from "vuex";

export default {
  name: "categories-dropdown-component",
  emits: ['returnToFirstPage'],
  setup(props, {emit}) {
    const {categories, removeSelectCategory, removeSelectType, setCategory, setType} = useCategories(emit);
    const selectedItem = ref()
    const visible = ref(false)
    const {dispatch, getters} = useStore()
    const searchValue = computed(() => getters.filter.search)

    onMounted(() => {
      if (categories.value) {
        selectedItem.value = categories.value[0].name;
      }
    })

    const selectDropdownItem = e => selectedItem.value = e.target.innerText

    const changeDropdown = () => {
      visible.value = !visible.value
      let pageContent = document.querySelector('.page-content')
      visible.value
          ? pageContent ? pageContent.style.overflow = 'inherit' : null
          : pageContent ? pageContent.style.overflow = 'hidden' : null
    }

    const clearField = async () => {
      await removeSelectCategory()
      await removeSelectType()
      await dispatch('getDocs')
      selectedItem.value = null
      hideDropdown()
    }
    const hideDropdown = () => visible.value = false

    const selectCategory = (e, category) => {
      hideDropdown()
      selectDropdownItem(e)
      setCategory(e, category)
    }
    const selectType = (e, item, category) => {
      hideDropdown()
      selectDropdownItem(e)
      setType(e, item, category)
    }

    watch(searchValue, async () => {
      removeSelectCategory()
      removeSelectType()
      selectedItem.value = null
    })

    return {
      categories,
      visible,
      selectedItem,
      selectCategory,
      changeDropdown,
      selectType,
      clearField,
      hideDropdown
    }
  },
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>