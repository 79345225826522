export const getters = {
   docs: state => state.docs,
   pageLoader: state => state.pageLoader,
   catLoader: state => state.catLoader,
   metaDataLoader: state => state.metaDataLoader,
   responsiveWidth: state => state.responsiveWidth,
   filter: state => state.filter,
   fields: state => state.fields,
   sort: state => state.sort,
   options: state => state.options,
   perPage: state => state.pagination.perPage,
   categoryId: state => state.categoryId,
   typeId: state => state.typeId,
   totalRecords: state => state.pagination.totalRecords,
   pagesCountVariants: state => state.pagination.pagesCountVariants,
   currentMetaData: state => state.currentMetaData,
   documentsCategories: state => state.documentsCategories,
   excludedCategories: state => state.excludedCategories,
   excludedTypes: state => state.excludedTypes,
   contactId: state => state.contactId
}
