<template>
  <Select
      @select="changePerPage"
      :class="[$style.rbs__docs_dropdown]"
      :clear-mode="false"
      :beforeSelectedItem="pagesCountVariants[0]"
      :items="pagesCountVariants"/>
</template>

<script>

import {useStore} from 'vuex'
import {computed} from 'vue'
import Select from "@/ui/select-component";
import {resetAccordion} from "@/utils/accordion";

export default {
  name: "perPage-component",
  components: {Select},
  setup(props, {emit}) {
    const {getters, commit, dispatch} = useStore()
    const pagesCountVariants = computed(() => getters.pagesCountVariants)

    const changePerPage = async num => {
      emit('returnToFirstPage')
      resetAccordion()
      await commit('changePerPage', num)
      await commit('setSkipRecords', 0)
      await dispatch('getDocs')
    }

    return {pagesCountVariants, changePerPage}
  },
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>