<div :class="[$style.rbs__docs]">
    <div :class="$style.rbs__docs_container">
        <div :class="$style.rbs__docs_content">
            <div :class="$style.rbs__docs_sidebar">
                <Sidebar :categories="categories"
                         @returnToFirstPage="pagination.firstPage"
                         :loading="pageLoader"
                         :items="items"/>
            </div>
            <div :class="$style.rbs__docs_datatable">
                <Header
                        @sortBy="key => sortBy(key, true)"
                        @returnToFirstPage="pagination.firstPage"
                        :items="items"
                        :fields="fields"
                        :loading="pageLoader"
                        :sort="sort"/>
                <Body
                        :items="items"
                        :loader="pageLoader"/>
                <Footer
                        @prevPage="prevPage"
                        @nextPage="nextPage"
                        :loading="pageLoader"
                        :pagination="pagination"/>
            </div>
        </div>
    </div>
</div>
