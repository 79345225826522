<template>
  <div v-if="isOpen" :class="$style.rbs__modal">
    <div :class="$style.rbs__overlay" @click="hide"></div>
    <div :class="$style.rbs__docs_view">
      <div :class="$style.rbs__docs_view_header">
        <template v-if="responsive">
          <div :class="$style.rbs__docs_view_close"
               @click="hide">
            Close
          </div>
        </template>
        <template v-else>
          <img src="/img/widgets/icons/times-white.svg"
               alt="times-white.svg"
               @click="hide"
               :class="$style.rbs__docs_view_close">
        </template>
      </div>
      <div v-if="file.fileContentPathRBS" :class="$style.rbs__docs_view_image">
        <template v-if="file.fileExtension === 'pdf'">

          <template v-if="responsive">
            <div :class="$style.rbs__docs_view_controls">
            <span v-if="showAllPages">
            {{ pageCount }} page(s)
            </span>
              <span v-else>
              <button :disabled="page <= 1" @click="page--">❮</button>
            {{ page }} / {{ pageCount }}
             <button :disabled="page >= pageCount" @click="page++">❯</button>
          </span>
              <div v-if="showAllPages" @click="showAllPages = false"> Show all pages</div>
              <div v-else @click="showAllPages = true">Paginated output</div>
            </div>

            <vue-pdf-embed
                ref="pdfRef"
                :disableAnnotationLayer="false"
                :show-zoom="true"
                :scale="2"
                :source="file.fileContentPathRBS"
                :page="page"
                @password-requested="handlePasswordRequest"
                @rendered="handleDocumentRender"
            />
          </template>
          <template v-else>
            <iframe :src="file.fileContentPathRBS"
                    style="width: 100%; height: 100%" frameborder="0">
              Ваш браузер не поддерживает фреймы
            </iframe>
          </template>

        </template>
        <template v-else>
          <img
              v-lazy="{ src: `${file.fileContentPathRBS}`, loading: '/img/widgets/lazy/loading.gif', error: 'your error image url' }"
              lazy="loading">
        </template>
      </div>
      <div :class="[$style.rbs__docs_view_image, $style.empty]">you cannot view the file</div>
    </div>
  </div>
</template>

<script>

import {computed, onUpdated, ref, watchEffect} from 'vue'
import {useResponsive} from "@/composition/widgets/common/useResponsive";
import {useStore} from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: "view-img-component",
  components: {VuePdfEmbed},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: null
    }
  },
  setup(props, {emit}) {

    const isLoading = ref(true)
    const page = ref(null)
    const pageCount = ref(1)
    const showAllPages = ref(true)
    const {getters} = useStore()
    const responsiveWidth = computed(() => getters.responsiveWidth)
    const pdfRef = ref(null)
    const {responsive} = useResponsive(responsiveWidth.value)

    const handleDocumentRender = () => {
      isLoading.value = false
      pageCount.value = pdfRef.value.pageCount
    }

    const handlePasswordRequest = (callback, retry) => {
      callback(prompt(retry
          ? 'Enter password again'
          : 'Enter password'
      ))
    }

    watchEffect(() => {
      page.value = showAllPages.value ? null : 1
    })

    onUpdated(() => {
      props.isOpen
          ? document.querySelector('body').style.overflow = 'hidden'
          : document.querySelector('body').style.overflow = 'auto'
    })

    const hide = () => {
      isLoading.value = true
      emit('hide')
    }
    const endLoading = () => isLoading.value = false

    const isImageExists = computed(() => {
      let result = false
      let img = new Image();
      img.src = props.file;
      img.addEventListener('load', () => result = true);
      return result
    })
    return {
      isImageExists,
      responsive,
      isLoading,
      endLoading,
      hide,
      page,
      pageCount,
      showAllPages,
      handleDocumentRender,
      handlePasswordRequest,
      pdfRef
    }
  }
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>