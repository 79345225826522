<template>
  <div v-if="loader" style="height: 150px; display: flex; align-items: center; justify-content: center;">
    <div>
      <div :class="$style.rbs__datatable_loader">
        <Loader/>
      </div>
    </div>
  </div>
  <div v-else-if="items?.length" :class="$style.rbs__docs_body">
    <div :class="[$style.rbs__datatable_mobile_item, $style.rbs__docs_body_section]"
         data-table
         ref="targetItem"
         @click="toggle($event, item.id)"
         v-for="item in items"
         :key="item.id">
      <div :class="$style.rbs__docs_head">
        <div :class="$style.rbs__datatable_mobile_toggler"
             data-toggler-head
             :style="['background: #cbaa5c']">
          <div :class="$style.rbs__docs_head_loader"
               style="display: none;"
               data-icon-loader>
            <Loader/>
          </div>
          <img data-icon-plus
               :src="'/img/widgets/icons/plus-white-icon.svg'"
               alt="plus-white-icon">
          <img data-icon-minus
               style="display: none;"
               :src="'/img/widgets/icons/minus-white-icon.svg'"
               alt="minus-white-icon">
        </div>
        <div :class="$style.rbs__docs_head_items">
          <div :class="$style.rbs__docs_head_name" data-table-name>
            <template v-if="item.fileName">
              <div :class="$style.rbs__docs_format">
                <img :src="'/img/widgets/icons/format_label.svg'" alt="format_label">
                <span>
                  <template v-if="item.fileExtension">{{ item.fileExtension.substr(0, 4) }}</template>
                  <template v-else>file</template>
                </span>
              </div>
              <div @click.stop="openImage(item)"
                   :class="[$style.rbs__docs_head_view, !isImage(item.fileExtension) ? $style.not_image : null]">
                {{ getFileName(item) }}
              </div>
            </template>
            <span style="opacity: 0.7"
                  v-else>
              not specified
            </span>
          </div>
          <a :href="cutUrl(item.fileContentPath)"
             :class="$style.rbs__docs_head_download"
             @click.stop>
            <img :src="'/img/widgets/icons/download_label.svg'" alt="download_label">
          </a>
        </div>
      </div>
      <div :class="$style.rbs__datatable_mobile_body" data-body>
        <div :class="$style.rbs__datatable_mobile_body_border"
             data-body-border
             style='background: #B29551'>
        </div>
        <div :class="$style.rbs__docs_body_items" data-items>
          <div v-if="responsive"
               :class="$style.rbs__docs_body_date">
            <Item
                :item="item.uploadDate ? reformatDate(item.uploadDate) : null"
                :title="'upload Date'"/>
          </div>
          <template v-if="!getMeta(item.id)">
            <div :class="$style.rbs__docs_body_loader">
              <Loader/>
            </div>
          </template>
          <template v-else-if="getMeta(item.id)">
            <template v-if="getMeta(item.id).length">
              <Item v-for="(el, idx) in getMeta(item.id)"
                    :key="idx"
                    :item="getReformatItem(el)"
                    :title="el.Label"/>
            </template>
            <template v-else>
              <span :class="$style.rbs__docs_body_empty">
                no metadata
              </span>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
  <Empty v-else :mobile="true" :text="'no documents'"/>
  <ViewImage
      :isOpen="IsOpenViewImage"
      :file="file"
      @hide="IsOpenViewImage=false; file=null"/>
</template>

<script>

import ViewImage from './view-img-component'
import Loader from '@/ui/loader-component'
import Item from '@/components/common/table-body-item-mob-component'
import {reformatDate} from "@/utils/time_operations";
import {toggleHead} from "@/utils/accordion";
import Empty from '@/components/common/table-no-items-component'
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useActions} from "@/composition/widgets/common/useActions";
import {useResponsive} from "@/composition/widgets/common/useResponsive";

export default {
  name: "mobile-body",
  components: {Loader, Empty, Item, ViewImage},
  data: () => ({
    isShowHead: false,
  }),
  props: ['items', 'loader'],
  emits: ['selectAction', 'hideActions', 'checkActions'],
  setup() {
    const {getters, dispatch} = useStore()
    const responsiveWidth = computed(() => getters.responsiveWidth)
    const {responsive} = useResponsive(responsiveWidth.value)
    const {selectActionPackages} = useActions()
    const metaData = computed(() => getters.currentMetaData)
    const IsOpenViewImage = ref(false)
    const file = ref(null)

    const getReformatItem = item => {
      let result = item.Value
      if (item.SubType === 'datetime-local') {
        result = reformatDate(result, 'MM/DD/YYYY hh:mm A')
      } else if (item.Type === 'date') {
        result = reformatDate(result)
      }
      return result
    }

    const openImage = item => {
      file.value = item
      file.value['fileContentPathRBS'] = `/api/fileContent/${item.externalPathP.split('/').pop()}`
      IsOpenViewImage.value = true
    }

    const cutUrl = url => {
      if (url.includes('fileContent')) {
        if (window.docsWidget.userIsAuthenticated) {
          // Authorized user
          return url.replace('fileContent', 'file')
        } else {
          // Unauthorized user
          return url.replace('fileContent', 'publicFile')
        }
      }

      return url
    }

    const allowedImagesTypes = ['bmp', 'pdf', 'jpeg', 'jpg', 'png', 'webp']

    const isImage = extension => {
      return allowedImagesTypes.find(el => el === extension.toLowerCase())
    }

    const getFileName = item => {
      if (item.description) {
        return item.description
      } else {
        const newName = item.fileName.replace(`.${item.fileExtension}`, '')
        if (newName) return newName
        else return item.fileName
      }
    }

    const toggle = async (e, id) => {
      const item = e.target.closest('[data-table]')
      const loader = item.querySelector('[data-icon-loader]')
      loader.style.display = 'block'
      if (!item.classList.contains('open')) await dispatch('getMetaDataByDocument', id)
      await toggleHead(e)
      loader.style.display = 'none'
    }

    const getMeta = id => {
      const item = metaData.value.find(el => el.id === id)
      return item?.data || null
    }

    return {
      IsOpenViewImage,
      metaData,
      responsive,
      reformatDate,
      selectActionPackages,
      toggle,
      isImage,
      file,
      getMeta,
      getReformatItem,
      openImage,
      getFileName,
      cutUrl
    }
  }
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>
