import {useStore} from "vuex"
import {computed} from "vue";
import {resetAccordionFromOnlyToggle, showAcc} from "@/utils/accordion";

export const useCategories = (emit) => {
    const {getters, dispatch, commit} = useStore()
    const categories = computed(() => getters.documentsCategories)
    const categoryId = computed(() => getters.categoryId)
    const typeId = computed(() => getters.typeId)

    const resetSettings = () => {
        resetAccordionFromOnlyToggle()
        removeSelectCategory()
        removeSelectType()
    }

    const removeSelectCategory = () => {
        commit('setCategoryId', null)
        document.querySelectorAll('[data-acc-name]')
            .forEach(el => el.style.fontWeight = '400')
    }
    const removeSelectType = () => {
        commit('setTypeId', null)
        document.querySelectorAll('[data-acc-item]')
            .forEach(el => el.style.fontWeight = '400')
    }

    const setCategory = async (e, item) => {
        if (categoryId.value === item.id && typeId.value === null) {
            return;
        }
        
        if (!e.target.closest('[data-acc]').classList.contains('open')) {
            resetAccordionFromOnlyToggle()
        }
        showAcc(e)
        
        removeSelectCategory()
        e.target.style.fontWeight = '600'
        commit('setCategoryId', item.id)
        commit('setTypeId', null)
        await dispatch('getDocs')
        
        emit('returnToFirstPage')
        removeSelectType()
    }

    const setType = async (e, type, category) => {
        if (typeId.value === type.id) {
            return;
        }
        
        removeSelectType()
        e.target.closest('[data-acc-item]').style.fontWeight = '600'
        if (category.id !== categoryId.value) {
            removeSelectCategory()
            e.target.closest('[data-acc]').querySelector('[data-acc-name]').style.fontWeight = '600'
        }
        commit('setCategoryId', category.id)
        commit('setTypeId', type.id)
        emit('returnToFirstPage')
        await dispatch('getDocs')
    }

    return {categories, removeSelectCategory, removeSelectType, setCategory, resetSettings, setType}
}