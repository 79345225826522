<template>
  <div :class="$style.rbs__docs_sidebar">
    <div :class="$style.rbs__docs_sidebar_top">
      <Search
          @returnToFirstPage="$emit('returnToFirstPage')"
          :loading="loading"/>
      <PerPage
          @returnToFirstPage="$emit('returnToFirstPage')"
          :style="[loading ? 'opacity: 0.7; pointer-events: none' : null,
           !responsive ? 'display: none;' : null]"/>
    </div>
    <div v-if="loader" :class="$style.rbs__docs_categories_loader">
      <Loader/>
    </div>
    <div v-else-if="categories?.length" :class="$style.rbs__docs_categories"
         :style="[loading ? 'opacity: 0.7; pointer-events: none' : null]">
      <CategoriesAccordion v-if="!responsive"
                           @returnToFirstPage="$emit('returnToFirstPage')"/>
      <CategoriesDropdown v-else
                          @returnToFirstPage="$emit('returnToFirstPage')"/>
    </div>
    <div v-else :class="$style.rbs__docs_categories_empty">
      no categories
    </div>
  </div>
</template>

<script>

import {useStore} from 'vuex'
import {computed} from 'vue'
import Search from './search-component'
import CategoriesAccordion from './categories-acc-component'
import CategoriesDropdown from './categories-dropdown-component'
import PerPage from '../perpage/perpage-component'
import {useResponsive} from "@/composition/widgets/common/useResponsive";
import Loader from "@/ui/loader-component";

export default {
  name: "sidebar-component",
  props: ['categories', 'items', 'loading'],
  emits: ['returnToFirstPage'],
  components: {PerPage, Search, CategoriesAccordion, CategoriesDropdown, Loader},
  setup() {
    const {getters} = useStore()
    const responsiveWidth = computed(() => getters.responsiveWidth)
    const loader = computed(() => getters.catLoader)

    const {responsive} = useResponsive(responsiveWidth.value)

    return {
      responsive, loader
    }
  }
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>
