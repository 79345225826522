import 'core-js/features/reflect';
import 'core-js/features/promise';
import 'core-js/features/map';
import {createApp} from 'vue';
import App from './App.vue'
import vClickOutside from "click-outside-vue3"
import store from '../../../../../store/docs_index';
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App);


app
    .use(store)
    .use(vClickOutside)
    .use(VueLazyLoad)
    .mount('#docs_app');


