import {createStore} from 'vuex'
import Docs from './modules/docs'

const store = createStore({
    modules: {
        Docs
    }
})

export default store
