<template>
  <div 
    :class="[{ 'open': index === 0 }, $style.rbs__docs_category]"
    data-acc
    ref="targetItem"
    @click="setCategory($event, category)"
    v-for="(category, index) in categories"
    :key="category.id"
  >
    <div :class="$style.rbs__docs_category_name"
         data-acc-name>
      {{ category.name }}
    </div>
    <div :class="$style.rbs__datatable_mobile_body"
         data-acc-body>
      <div :class="$style.rbs__datatable_mobile_body_border"
           data-acc-border>
      </div>
      <div :class="$style.rbs__docs_category_items">
        <div :class="$style.rbs__docs_category_item"
             data-acc-item
             v-for="item in category.types"
             :key="item.id" @click.stop="setType($event, item, category)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useCategories} from "@/composition/widgets/docs/useCategories";

export default {
  name: "categories-acc-component",
  emits: ['returnToFirstPage'],
  setup(props, {emit}) {
    const {categories, setCategory, setType} = useCategories(emit);

    return {categories, setCategory, setType}
  }
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>