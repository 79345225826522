<template>
  <div :class="[$style.rbs__docs_search]"
       :style="[loading ? 'opacity: 0.5; pointer-events: none' : null]">
    <img :class="$style.rbs__datatable_mobile_search_icon"
         :src="'/img/widgets/icons/search-icon.svg'"
         alt="search">
    <input type="text"
           :readonly="loading"
           placeholder="Search"
           v-model="searchValue"
           @input="getSearch"
           :class="$style.rbs__docs_input">
    <div @click="resetSearch"
         :class="$style.rbs__datatable_mobile_search_clear"
         v-if="searchValue">
      <img :src="'/img/widgets/icons/times.svg'" alt="times">
    </div>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ref} from "vue";
import {useCategories} from "@/composition/widgets/docs/useCategories";

export default {
  name: "search-component",
  props: ['loading'],
  setup(props, {emit}) {
    const searchValue = ref('')
    const timeout = ref()
    const {dispatch, commit} = useStore()
    const {resetSettings} = useCategories();

    const getSearch = e => {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }
      timeout.value = setTimeout(function () {
        resetSettings()
        commit('setSearchValue', e.target.value)
        emit('returnToFirstPage')
        dispatch('getDocs')
      }, 800);
    }

    const resetSearch = async () => {
      searchValue.value = ''
      resetSettings()
      emit('returnToFirstPage')
      await commit('setSearchValue', null)
      await dispatch('getDocs')
    }

    return {
      searchValue,
      getSearch,
      resetSearch
    }
  },
}
</script>

<style module lang="scss" src='../../../../assets/styles/docs/styles.scss'/>