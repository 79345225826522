<template>
  <Docs/>


</template>

<script>

import Docs from '@/components/widgets/docs/docs-component'

export default {
  components: {Docs},
}
</script>
